.ReactTable > .rt-table {
  overflow: auto;
}

.list-table-selected-text-section {
  flex-grow: 0;
  margin-right: 12px;
  margin-bottom: 4px;
  justify-content: center;
  text-align: justify;
  white-space: nowrap;
}
