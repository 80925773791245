.product-links {
  padding-left: 0;
  margin-top: 8px;
  font-size: 14px;
  display: block;
  cursor: pointer;
  color: black;
  width: max-content;
  max-width: 100%;
}

.product-links:visited {
  color: black;
}

.product-links:hover {
  color: #0088a9;
}
