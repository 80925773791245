.ReactTable > .rt-table {
  overflow: auto;
}

.ReactTable .rt-td {
  overflow: hidden;
  text-overflow: ellipsis;
}

.ReactTable .rt-pagination .pagination {
  padding: 0 !important; /* Override for 40px padding coming from component library */
}

.flex-row {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 1;
  /* flex-wrap: wrap; */
}

.flex-column {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  /* flex-wrap: wrap; */
}

.flex-row-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.flex-column-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.pd-container {
  width: 70%;
  margin: auto;
  max-width: 1200px;
}

.pd-breadcrumbs {
  padding: 0px 0px !important;
  margin-bottom: 16px !important;
}

.pd-drawer-heading {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
}
