.filter-container {
  background-color: white;
  border-radius: 5px;
  /* border: 1px solid #c4cdd6; */
  /* border: 1px solid #e1e6eb; */
}

.filter-accordion {
  /* border: 1px solid #c4cdd6 !important; */
  border: 1px solid #e1e6eb !important;
}

.filter-accordion .accordion-body {
  padding: 4px 16px !important;
  max-height: 300px !important;
  overflow: scroll;
}

.filters-heading-section {
  background-color: #eff3f5;
  padding: 16px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 5px 5px 0px 0px;
  border-bottom: 1px solid #c4cdd6;
}
