.production-delay-search-section {
  max-width: 1000px;
  margin-bottom: 12px;
}

.list-screen-search-button {
  height: 48px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  padding: 11px 12px !important;
}
