/* prevent underline on title */
.navTitle:hover,
.navTitle:visited,
.navTitle:link,
.navTitle:active {
  text-decoration: none;
}
.navTitle {
  font-weight: 400 !important;
}

.root-child {
  height: calc(100vh - 120px);
  overflow: auto;
  padding: 0 30px;
}
