.production-delay-filters-section {
  min-width: 280px;
  width: 20%;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 32px;
  border-radius: 5px;
}

.production-delay-list-table-section {
  width: calc(100vw - 20% - 92px); /* 92px is the fixed combined total of paddings/mergins */
  max-width: calc(100vw - 372px); /* 92px is the fixed combined total of paddings/mergins */
  flex-grow: 0;
  flex-shrink: 0;
}
