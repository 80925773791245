.ptd-edit-card {
  padding: 24px;
  margin-bottom: 20px;
}

.ptd-edit-card > .card-block {
  padding: 0;
}

.pd-edit-breadcrumbs {
  padding: 0px 0px !important;
  margin-bottom: 0px !important;
}

.delay-schedule-card {
  border: 1px solid #c4cdd6;
  padding: 16px 24px 24px 24px;
}

.delay-schedule-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.delay-schedule-footer button:first-child {
  margin-right: 12px;
}

.delay-time-text {
  margin-right: 16px;
  width: 142px;
}

.delay-unit-select {
  width: 142px;
  height: 48px;
}

.delay-unit-select > .react-select-wrapper > div:last-child > div:first-of-type {
  min-height: 0px;
  height: 48px;
}

.ptd-accordion .accordion-body {
  padding: 0px !important;
}

.ptd-accordion .delay-schedule-card {
  padding-top: 10px;
}

.rdtDays > table {
  margin: 0px !important;
}

.ptd-accordion.accordion-open div:has(.accordion-body) {
  overflow: visible !important;
}
